import * as React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import { Link } from 'gatsby';

// markup
const NotFoundPage = () => {
    return (
        <main>
            <Header />
            <div className="flex justify-center mt-64 mb-64">
                Sorry &nbsp;
                <span role="img" aria-label="Pensive emoji">
                    😔
                </span>{' '}
                &nbsp; we couldn’t find what you were looking for.&nbsp;
                <Link className="mb-64" to="/">
                    {' '}
                    Go home
                </Link>
                .
            </div>
            <Footer />
        </main>
    );
};

export default NotFoundPage;
