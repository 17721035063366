import React from 'react';
import { Link } from 'gatsby';

const Header = () => (
    <header className="text-white body-font">
        <div className="bg-green-500">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <Link
                    to={'/'}
                    className="flex title-font font-medium items-center text-gray mb-4 md:mb-0"
                >
                    <span className="text-xl">Carlos Aguilera</span>
                </Link>
                <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                    <Link to={"/blog"} className="mr-5 hover:text-gray-200">Blog</Link>
                </nav>
                {/* <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                    <Link to={"/contact"} className="mr-5 hover:text-gray-200">Contact</Link>
                </nav> */}
            </div>
        </div>
    </header>
);

export default Header;
